<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import { GetDataMethods, PostDataMethods } from "@/state/helpers";

/**
 * Orders Component
 */
export default {
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            title: "Payments",
            items: [
                {
                    text: "Wesal",
                },
                {
                    text: "Payments",
                },
            ],
            isVisible: false,
            countPage: 1,
            paymentsData: [],
            currentPage: 0,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: "",
            filterOn: [],
            sortBy: "id",
            sortDesc: true,
            fields: [
                { key: "id", sortable: true, label: "ID" },
                { key: "point_now", sortable: false, label: "Points User" },
                { key: "point", sortable: false, label: "Points User Now" },
                { key: "type", sortable: false, label: "Type" },
                { key: "status", sortable: false, label: "Status" },
                { key: "top_up_id", sortable: false, label: "Tup up Id" },
                { key: "user_id", sortable: false, label: "User Id" },
                { key: "created_at", sortable: false, label: "Created At" },
                { key: "updated_at", sortable: false, label: "Updated At" },
            ],
        };
    },
    computed: {
        /**
         * Total no. of records
         *
         */
        rows() {
            return this.countPage
        }

    },

    mounted() {
        this.GetUsersData();
    },



    watch: {
        currentPage: function () {
            this.GetUsersData(this.currentPage);
        },
    },


    methods: {
        ...GetDataMethods,
        ...PostDataMethods,

        GetUsersData(page) {
            this.getData("get_payments?page=" + page).then((res) => {
                this.paymentsData = res.data.data.data;
                this.countPage = res.data.data.countPage;
            });
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body pt-0">
                        <b-tabs nav-class="nav-tabs-custom">
                            <b-tab title-link-class="p-3">
                                <template v-slot:title>
                                    <a class="font-weight-bold active">Paymesnt Log</a>
                                </template>
                                <div class="row mt-4">

                                    <!-- Search -->
                                    <div class="col-sm-12 col-md-6">
                                        <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                            <label class="d-inline-flex align-items-center">
                                                Search:
                                                <b-form-input v-model="filter" type="search" placeholder="Search..."
                                                    class="form-control form-control-sm ml-2"></b-form-input>
                                            </label>
                                        </div>
                                    </div>

                                    <!-- End search -->
                                </div>

                                <div class="table-responsive">
                                    <b-table class="table-centered" id="user-tab" :items="paymentsData" :fields="fields"
                                        responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                                        :filter="filter" :per-page="perPage" :filter-included-fields="filterOn">

                                        <template v-slot:cell(top_up_id)="data">
                                            <div>{{ data.value = null }}</div>
                                        </template>

                                        <template v-slot:cell(updated_at)="data">
                                            <div>{{ data.value | formatDate }}</div>
                                        </template>
                                        <template v-slot:cell(created_at)="data">
                                            <div>{{ data.value | formatDate }}</div>
                                        </template>
                                    </b-table>

                                    <b-modal ref="my-modal" size="sm" hide-footer no-close-on-backdrop
                                        id="modal-backdrop" title="Delete" title-class="font-18">
                                        <p>Are you sure to delete this user ?</p>
                                        <b-button class="mt-2 mr-2" variant="outline-danger"
                                            @click="DeleteUser()">OK</b-button>
                                        <b-button class="mt-2" variant="outline-primary"
                                            @click="$bvModal.hide('modal-backdrop')">Cancel</b-button>
                                    </b-modal>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="dataTables_paginate paging_simple_numbers float-right">
                                            <ul class="pagination pagination-rounded mb-0">
                                                <!-- pagination -->
                                                <b-pagination v-model="currentPage" :totalRows="rows"
                                                    :per-page="perPage" aria-controls="user-tab">
                                                </b-pagination>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
